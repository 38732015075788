import {
  CLEAR_FLAGS_PARAMETROS_INTEGRACAO,
  SET_PARAMETROS_INTEGRACAO_INIT,
  SET_PARAMETROS_INTEGRACAO_SUCCESS,
  ERROR_PARAMETROS_INTEGRACAO_MSG
} from '../../actionTypes';

export const clearFlagsParametrosIntegracao = () => ({
  type: CLEAR_FLAGS_PARAMETROS_INTEGRACAO,
  payload: {}
});

export const setParametrosIntegracaoInit = ({
  usuarioMongoAppServices,
  empId,
  parametrizacaoIntegracao
}) => ({
  type: SET_PARAMETROS_INTEGRACAO_INIT,
  payload: { usuarioMongoAppServices, empId, parametrizacaoIntegracao }
});

export const setParametrosIntegracaoSuccess = ({ msg }) => {
  return {
    type: SET_PARAMETROS_INTEGRACAO_SUCCESS,
    payload: { msg }
  };
};

export const errorParametrosIntegracaoMsg = (data) => ({
  type: ERROR_PARAMETROS_INTEGRACAO_MSG,
  payload: data
});
