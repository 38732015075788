import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { FarmaError } from 'src/integracoes/modelos/erros';
import { User as RealmUser } from 'realm-web';
import { IParametrosGlobais } from 'src/integracoes/servicos/mongo-atlas/modelos/IParametrosGlobais';
import { getParametrosGlobaisSuccess, parametrosGlobaisErrorMsg, updateParametrosGlobaisSuccess } from './actions';
import { GET_PARAMETROS_GLOBAIS_INIT, UPDATE_PARAMETROS_GLOBAIS_INIT } from '../actionTypes';
import { ParametrosGlobais } from 'src/integracoes/servicos/mongo-atlas/funcoes/parametrosGlobais';

interface FetchListaParametrosGlobaisResult {
  isError: boolean;
  parametrosGlobais: IParametrosGlobais | null;
  errorMessage: string;
}

// -------------------Obter Paramêtros Globais-----------------------------

const obtemParametrosGlobaisAsync = async ({ usuarioMongoAppServices }: { usuarioMongoAppServices: RealmUser }) => {
  try {
    const { status, dados, mensagem } = await ParametrosGlobais.obtem(usuarioMongoAppServices);

    if (status) {
      return {
        isError: false,
        parametrosGlobais: dados,
        errorMessage: ''
      };
    }
    return {
      isError: true,
      parametrosGlobais: null,
      errorMessage: mensagem
    };
  } catch (error: any) {
    throw new FarmaError(error);
  }
};

function* obtemParametrosGlobaisMiddle({ payload }: { payload: any, type: any }) {
  const { usuarioMongoAppServices } = payload;
  try {
    const resp: FetchListaParametrosGlobaisResult = yield call(obtemParametrosGlobaisAsync, { usuarioMongoAppServices });
    if (!resp.isError) {
      yield put(getParametrosGlobaisSuccess({ parametrosGlobais: resp.parametrosGlobais }));
    } else {
      yield put(parametrosGlobaisErrorMsg({ msg: resp.errorMessage }));
    }
  } catch (error: any) {
    const mensagem = error.mensagem ? `${error.mensagem}` : `${error.message}`;
    yield put(parametrosGlobaisErrorMsg({ msg: mensagem }));
  }
}

export function* watchObtemParametrosGlobais() {
  yield takeEvery(GET_PARAMETROS_GLOBAIS_INIT, obtemParametrosGlobaisMiddle);
}

// --------------------------- Atualiza Paramêtros Globais ---------------------------------------------------------

const atualizaParametrosGlobaisAsync = async ({ usuarioMongoAppServices, parametros }:
  { usuarioMongoAppServices: RealmUser, parametros: IParametrosGlobais }
) => {
  try {
    const { status, dados, mensagem } = await ParametrosGlobais.atualiza(usuarioMongoAppServices, parametros);
    if (status) {
      return {
        isUpdated: true,
        msg: mensagem,
        data: dados
      };
    } else {
      return { isUpdated: false, msg: mensagem, data: null };
    }
  } catch (error: any) {
    throw new FarmaError(error);
  }
};

function* atualizaParametrosGlobaisMiddle({ payload }: { payload: any, type: any }) {
  const { usuarioMongoAppServices, parametros } = payload;
  try {
    const { isUpdated, msg } = yield call(atualizaParametrosGlobaisAsync, {
      usuarioMongoAppServices,
      parametros
    });
    if (isUpdated) {
      yield put(updateParametrosGlobaisSuccess({ msg }));
    } else {
      yield put(parametrosGlobaisErrorMsg({ msg }));
    }
  } catch (error: any) {
    const mensagem = error.mensagem ? `${error.mensagem}` : `${error.message}`;
    yield put(parametrosGlobaisErrorMsg({ msg: mensagem}));
  }
}

export function* watchAtualizaParametrosGlobais() {
  yield takeEvery(UPDATE_PARAMETROS_GLOBAIS_INIT, atualizaParametrosGlobaisMiddle);
}

// WATCHS
export default function* rootSaga() {
  yield all([
    fork(watchObtemParametrosGlobais),
    fork(watchAtualizaParametrosGlobais)
  ]);
}
