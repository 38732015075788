/* eslint-disable prettier/prettier */
import {
  PAPEL4,
  PAPEL3,
  PAPEL2,
  PAPEL1,
  PAPELDEFAULT,
  PAPEL5
} from 'src/utils/rbac/papeis';
import { portalName, primaryColor } from './config';
import {
  Trello as TrelloIcon,
  PieChart as PieChartIcon,
  CheckCircle as CheckCircleIcon,
  Settings as SettingsIcon,
} from 'react-feather';
import GroupAddOutlinedIcon from '@material-ui/icons/GroupAddOutlined';
import EmojiEventsOutlinedIcon from '@material-ui/icons/EmojiEventsOutlined';
import BeenhereOutlinedIcon from '@material-ui/icons/BeenhereOutlined';
import FindInPageOutlinedIcon from '@material-ui/icons/FindInPageOutlined';
import EqualizerOutlinedIcon from '@material-ui/icons/EqualizerOutlined';
import ApartmentIcon from '@material-ui/icons/Apartment';
import StoreIcon from '@material-ui/icons/Store';
import BallotIcon from '@material-ui/icons/Ballot';
import AssignmentIcon from '@material-ui/icons/Assignment';
import DashboardIcon from '@material-ui/icons/Dashboard';
import BeenhereIcon from '@material-ui/icons/Beenhere';

import { svgIcons } from '../src/utils/icons';
import { v1 as uuidv1 } from 'uuid';

export const APP_VERSION = '0.1.0';
export const ENABLE_REDUX_DEV_TOOLS = true;

export const THEMES = {
  LIGHT: 'LIGHT'
};
export const BRAND_PRIMARY_COLOR = primaryColor;

export const PTBR_TABLE_TRANSLATE = {
  pagination: {
    labelDisplayedRows: '{from}-{to} de {count}',
    labelRowsSelect: 'Linhas',
    labelRowsPerPage: 'Linhas por página',
    firstTooltip: 'Primeira página',
    previousTooltip: 'Página anterior',
    nextTooltip: 'Próxima página',
    lastTooltip: 'Última página'
  },
  toolbar: {
    nRowsSelected: '{0} linha(s) selecionada(s)',
    searchTooltip: 'Pesquisar',
    searchPlaceholder: 'Pesquisar'
  },
  header: {
    actions: 'Ações'
  },
  body: {
    emptyDataSourceMessage: 'Lista vazia',
    addTooltip: 'Adicionar',
    deleteTooltip: 'Deletar',
    editTooltip: 'Editar',
    filterRow: {
      filterTooltip: 'Filtrar'
    },
    editRow: {
      deleteText: 'Tem certeza de que deseja deletar este item?',
      cancelTooltip: 'Cancelar',
      saveTooltip: 'Salvar'
    }
  }
};

export const styleTable = {
  headerStyle: {
    borderLeft: '1px solid #f2f2f2',
    borderRight: '1px solid #f2f2f2',
    color: BRAND_PRIMARY_COLOR
  },
  cellStyle: {
    borderLeft: '1px solid #f2f2f2',
    borderRight: '1px solid #f2f2f2'
  }
};


export const meses = [
  { id: 1, name: 'Janeiro' },
  { id: 2, name: 'Fevereiro' },
  { id: 3, name: 'Março' },
  { id: 4, name: 'Abril' },
  { id: 5, name: 'Maio' },
  { id: 6, name: 'Junho' },
  { id: 7, name: 'Julho' },
  { id: 8, name: 'Agosto' },
  { id: 9, name: 'Setembro' },
  { id: 10, name: 'Outubro' },
  { id: 11, name: 'Novembro' },
  { id: 12, name: 'Dezembro' }
];

export const statusEmpresa = [
  { id: 1, status: 'Ativa' },
  { id: 2, status: 'Inativa' }
];

export const todosNiveisAcesso = [
  {
    id: 1,
    nivel: PAPELDEFAULT,
    nome: '',
    visivel: [PAPEL2, PAPEL3, PAPEL4, PAPEL5]
  },
  {
    id: 2,
    nivel: PAPEL1,
    nome: 'Visualização',
    visivel: [PAPEL2, PAPEL3, PAPEL4, PAPEL5]
  },
  {
    id: 3,
    nivel: PAPEL2,
    nome: 'Gestão',
    visivel: [PAPEL3, PAPEL4, PAPEL5]
  },
  {
    id: 4,
    nivel: PAPEL3,
    nome: 'Administração',
    visivel: [PAPEL4, PAPEL5]
  },
  {
    id: 5,
    nivel: PAPEL4,
    nome: 'Super Admin',
    visivel: ['']
  },
  {
    id: 6,
    nivel: PAPEL5,
    nome: 'Administração WL',
    visivel: [PAPEL4]
  }
];

export const valoresDefaultCurvaAbc = [
  { id: 'A', categoria: 'A', porcentagem: 60 },
  { id: 'B', categoria: 'B', porcentagem: 30 },
  { id: 'C', categoria: 'C', porcentagem: 10 }
];

export const valorDefaultParametroD = {
  id: 'D',
  categoria: 'D',
  dias: 90
};

export const iconArray: { name: string; icon: string; id: string; }[] = [];
let id: any = uuidv1();;

for (const iconName in svgIcons) {
  if (Object.hasOwnProperty.call(svgIcons, iconName)) {
    iconArray.push({ name: iconName, icon: iconName, id: id.toString() });
    id++;
  }
}

export const IMG_ERROR = '/static/img-error.svg';
export const LOGO_ERROR = '/static/logo-error.svg';
export const IMG_FUNDO = '/static/login-bg.svg';

// ------ nomes atributos salvos banco de dados
export const ticketMedio = 'ticket';
export const numeroCupons = 'cupons';

export const sectionsMenu = [
  {
    subheader: 'Relatórios',
    allowedRoles: [PAPEL1, PAPEL2, PAPEL3, PAPEL4, PAPEL5],
    items: []
  },
  {
    subheader: 'Configurações',
    allowedRoles: [PAPEL2, PAPEL3, PAPEL4, PAPEL5],
    items: [
      {
        title: 'Usuários',
        icon: GroupAddOutlinedIcon,
        href: '/gerenciar-usuarios/lista',
        allowedRoles: [PAPEL2, PAPEL3, PAPEL4, PAPEL5]
      },
      {
        title: 'Grupos de Controle',
        icon: FindInPageOutlinedIcon,
        href: '/grupos-controle',
        allowedRoles: [PAPEL3, PAPEL4, PAPEL5]
      },
      {
        title: 'Metas',
        icon: EmojiEventsOutlinedIcon,
        href: '/metas',
        allowedRoles: [PAPEL2, PAPEL3, PAPEL4, PAPEL5],
        items: [
          {
            title: 'Nova Meta',
            href: '/metas/nova',
            allowedRoles: [PAPEL2, PAPEL3, PAPEL4, PAPEL5]
          },
          {
            title: 'Acessa Lista',
            href: '/metas/gerenciar',
            allowedRoles: [PAPEL2, PAPEL3, PAPEL4, PAPEL5]
          },
          {
            title: 'Colaboradores',
            href: '/gerenciar-colaborador/lista',
            allowedRoles: [PAPEL2, PAPEL3, PAPEL4, PAPEL5]
          }
        ]
      },
      {
        title: 'Campanhas',
        icon: BeenhereOutlinedIcon,
        href: '/metas',
        allowedRoles: [PAPEL2, PAPEL3, PAPEL4, PAPEL5],
        items: [
          {
            title: 'Nova Campanha',
            href: '/campanha/nova',
            allowedRoles: [PAPEL2, PAPEL3, PAPEL4, PAPEL5]
          },
          {
            title: 'Acessa Lista',
            href: '/campanha/gerenciar',
            allowedRoles: [PAPEL2, PAPEL3, PAPEL4, PAPEL5]
          }
        ]
      },
      {
        title: 'Fatores de Correção',
        icon: CheckCircleIcon,
        href: '/fator',
        allowedRoles: [PAPEL2, PAPEL3, PAPEL4, PAPEL5],
        items: [
          {
            title: 'Novo Fator de Correção',
            href: '/fator/nova',
            allowedRoles: [PAPEL2, PAPEL3, PAPEL4, PAPEL5]
          },
          {
            title: 'Acessa Lista',
            href: '/fator/gerenciar',
            allowedRoles: [PAPEL2, PAPEL3, PAPEL4, PAPEL5]
          }
        ]
      },
      {
        title: 'Parâmetros de Estoque',
        icon: SettingsIcon,
        href: '/configuracoes',
        allowedRoles: [PAPEL2, PAPEL3, PAPEL4, PAPEL5],
        items: [
          {
            title: 'Curva ABC',
            href: '/configuracoes/curvaAbc',
            allowedRoles: [PAPEL2, PAPEL3, PAPEL4, PAPEL5]
          },
          {
            title: 'Excesso de Estoque',
            href: '/configuracoes/estoque',
            allowedRoles: [PAPEL2, PAPEL3, PAPEL4, PAPEL5]
          }
        ]
      },
      {
        title: 'Parâmetros BI',
        icon: DashboardIcon,
        href: '/parametrosBi',
        allowedRoles: [PAPEL4],
        items: [
          {
            title: 'Parâmetros Integração',
            href: '/parametrosBi/parametrosIntegracao',
            allowedRoles: [PAPEL4]
          },
          {
            title: 'Parâmetros Embedded',
            href: '/parametrosBi/parametrosEmbedded',
            allowedRoles: [PAPEL4]
          }
        ]
      },
      {
        title: 'Parâmetros Globais',
        icon: BeenhereIcon,
        href: '/parametrosGlobais',
        allowedRoles: [PAPEL4]
      }
    ]
  },
  {
    subheader: 'Administração',
    allowedRoles: [PAPEL3, PAPEL4, PAPEL5],
    items: [
      {
        title: 'Empresas',
        icon: ApartmentIcon,
        href: '/gerenciar-empresas/lista',
        allowedRoles: [PAPEL3, PAPEL4, PAPEL5]
      },
      {
        title: 'Classificações de Loja',
        icon: StoreIcon,
        href: '/classificacoes-loja',
        allowedRoles: [PAPEL4, PAPEL5]
      },
      {
        title: 'Motivos Status Empresa',
        icon: BallotIcon,
        href: '/motivosStatusEmpresa',
        allowedRoles: [PAPEL4]
      },
      {
        title: 'Gestão de Relatórios',
        icon: TrelloIcon,
        allowedRoles: [PAPEL4],
        items: [
          {
            title: 'Categorias de Painéis',
            href: '/paineis/categorias',
            allowedRoles: [PAPEL4]
          },
          {
            title: 'Painéis',
            href: '/paineis',
            allowedRoles: [PAPEL4]
          }
        ]
      },
      {
        title: 'Solicitações',
        icon: AssignmentIcon,
        allowedRoles: [PAPEL3, PAPEL4, PAPEL5],
        items: [
          {
            title: 'Nova ativação',
            href: '/solicitacoes/novaAtivacao',
            allowedRoles: [PAPEL3, PAPEL4, PAPEL5]
          },
          {
            title: 'Suporte',
            href: '/solicitacoes/suporte',
            allowedRoles: [PAPEL3, PAPEL4, PAPEL5]
          }
        ]
      }
    ]
  }
];

export const statusPowerBiEmbedded = [
  { id: 1, status: 'Ativo' },
  { id: 2, status: 'Inativo' }
];

export const atrNecessariosPbiRelatorio = ['pbiWorkspaceId', 'pbiReportId', 'pbiDatasetId'];

export enum Status {
  Criado = 'Novo usuário: aguardando primeiro acesso',
  Ativado = "Ativado: aguardando configuração pelo Gestor",
  Completo = "Ativado e configurado",
};

// valores iniciais das consultas tela parâmetros integração 
export const ParametrosIntegracaoValoresIniciaisConsultas = [
  {
    name: 'Clientes',
    id: uuidv1(),
    query: ''
  },
  {
    name: 'Empresas',
    id: uuidv1(),
    query: ''
  },
  {
    name: 'Codigo Barras',
    id: uuidv1(),
    query: ''
  },
  {
    name: 'Compras',
    id: uuidv1(),
    query: ''
  },
  {
    name: 'Estoque',
    id: uuidv1(),
    query: ''
  },
  {
    name: 'Fornecedores',
    id: uuidv1(),
    query: ''
  },
  {
    name: 'Funcionarios',
    id: uuidv1(),
    query: ''
  },
  {
    name: 'Produtos',
    id: uuidv1(),
    query: ''
  },
  {
    name: 'Vendas',
    id: uuidv1(),
    query: ''
  }
];

// valores iniciais atributo 'parametrizacaoIntegracao' tela parâmetros integração 
export const ParametrosIntegracaoValoresIniciais = {
  dsn: '',
  erp: '',
  urlPlanilhaFinanceiraBi: '',
  urlPlanilhaFinanceiraPublica: '',
  urlPlanilhaStatusClientesBi: '',
  urlPlanilhaStatusClientesPublica: '',
  urlArquivoDadosValidacao: '',

  host: '',
  nomeOuCaminhoBanco: '',
  porta: NaN,
  senha: '',
  usuario: '',

  portal: portalName || '',
  quantidadeLinhas: NaN,
  tipoBanco: '',
  tipoDestino: '',
  workers: NaN
};

// utilizado na tela de Pametros Integracao
export const tiposDestino = [
  { id: 1, tipo: 'Azure', valor: 'azure' },
  { id: 2, tipo: 'S3', valor: 's3' },
  { id: 3, tipo: 'Ambos', valor: 'ambos' }
];

// utilizado na tela de Pametros Integracao
export const tiposBanco = [
  { id: 1, tipo: 'MySQL' },
  { id: 2, tipo: 'PostgreSQL' },
  { id: 3, tipo: 'SQLServer' },
  { id: 4, tipo: 'Oracle' },
  { id: 5, tipo: 'Firebird' },
  { id: 6, tipo: 'MongoDB' }
];

export const valorDefaultTaxaCrescimento = 
  {
    id: uuidv1(),
    nome: 'Taxa',
    valor: 0
  };

export const valoresDefaultIndicesReferencia = [
  {
    nome: 'Índice Mercado',
    indice: 'indiceMercado',
    id: uuidv1(),
    valor: 0
  },
  {
    nome: 'Índice SLA',
    indice: 'indiceSLA',
    id: uuidv1(),
    valor: 0
  },
  {
    nome: 'Índice Margem Bruta',
    indice: 'indiceMargemBruta',
    id: uuidv1(),
    valor: 0
  },
  {
    nome: 'Índice SKU',
    indice: 'indiceSKU',
    id: uuidv1(),
    valor: 0
  },
  {
    nome: 'Índice Estoque',
    indice: 'indiceEstoque',
    id: uuidv1(),
    valor: 0
  },
  {
    nome: 'Índice Curva D',
    indice: 'indiceCurvaD',
    id: uuidv1(),
    valor: 0
  }
];

