import { User as RealmUser } from 'realm-web';
import { IRespostaFuncao } from 'src/integracoes/modelos/funcoes';
import { FarmaError } from 'src/integracoes/modelos/erros';
import { IParametrosGlobais } from '../modelos/IParametrosGlobais';

// ------------------------ OBTEM PARAMÊTROS GLOBAIS ------------------------ //
const obtem = async (
    usuarioMongoAppServices: RealmUser
): Promise<IRespostaFuncao<IParametrosGlobais>> => {
    return usuarioMongoAppServices.functions['parametrosGlobais/obtemParametrosGlobais']()
        .then((resultado) => resultado as IRespostaFuncao<IParametrosGlobais>)
        .catch((e: any) => {
            const erro = {
                nome: 'MONGODB-ERRO-DESCONHECIDO',
                mensagem: 'Erro ao obter Paramêtros Globais',
                causa: e
            };
            throw new FarmaError(erro);
        });
};

// ------------------------ ATUALIZAR PARAMÊTROS GLOBAIS ------------------------ //
const atualiza = async (
    usuarioMongoAppServices: RealmUser,
    parametrosGlobais: IParametrosGlobais
): Promise<IRespostaFuncao<IParametrosGlobais>> => {
    return usuarioMongoAppServices.functions['parametrosGlobais/atualizaParametrosGlobais'](parametrosGlobais)
        .then((resultado) => resultado as IRespostaFuncao<IParametrosGlobais>)
        .catch((e: any) => {
            const erro = {
                nome: 'MONGODB-ERRO-DESCONHECIDO',
                mensagem: 'Erro ao atualizar Paramêtros Globais',
                causa: e
            };
            throw new FarmaError(erro);
        });
};

export const ParametrosGlobais = {
    obtem,
    atualiza
}