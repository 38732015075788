import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { SET_PARAMETROS_INTEGRACAO_INIT } from '../../actionTypes';
import {
  setParametrosIntegracaoSuccess,
  errorParametrosIntegracaoMsg,
} from './actions';
import { Empresas } from 'src/integracoes/servicos/mongo-atlas/funcoes/empresas';
import { IEmpresaParametrosIntegracao } from 'src/integracoes/servicos/mongo-atlas/modelos/IEmpresa';

// Atualizar dados comunicacao com o banco -------------------------
const atualizaParametrosIntegracaoAsync = async ({ usuarioMongoAppServices, empId, parametrizacaoIntegracao }:
  { usuarioMongoAppServices: any, empId: string, parametrizacaoIntegracao: IEmpresaParametrosIntegracao }) => {
  const { status, mensagem } = await Empresas.atualizaParametrosIntegracao(usuarioMongoAppServices, empId, parametrizacaoIntegracao);
  if (status) {
    return { isUpdated: true, msg: mensagem };
  } else {
    return { isUpdated: false, msg: mensagem };
  }
};

function* atualizaParametrosIntegracaoMiddle({ payload }: { payload: any, type: any }) {
  const { usuarioMongoAppServices, empId, parametrizacaoIntegracao } = payload;
  try {
    const { isUpdated, msg } = yield call(atualizaParametrosIntegracaoAsync, {
      usuarioMongoAppServices,
      empId,
      parametrizacaoIntegracao
    });
    if (isUpdated) {
      yield put(setParametrosIntegracaoSuccess({ msg }));
    } else {
      yield put(errorParametrosIntegracaoMsg({ msg }));
    }
  } catch (error: any) {
    const mensagem = error.mensagem ? `${error.mensagem}` : `${error.message}`;
    yield put(errorParametrosIntegracaoMsg({ msg: mensagem }));
  }
}

export function* handleAtualizaParametrosIntegracao() {
  yield takeEvery(SET_PARAMETROS_INTEGRACAO_INIT, atualizaParametrosIntegracaoMiddle);
}

// WATCHS ------------------------------------------------
export default function* rootSaga() {
  yield all([fork(handleAtualizaParametrosIntegracao)]);
}
