import {
  GET_PARAMETROS_GLOBAIS_INIT,
  GET_PARAMETROS_GLOBAIS_SUCCESS,
  UPDATE_PARAMETROS_GLOBAIS_INIT,
  UPDATE_PARAMETROS_GLOBAIS_SUCCESS,
  PARAMETROS_GLOBAIS_ERROR_MSG,
  CLEAR_PARAMETROS_GLOBAIS
} from '../actionTypes';

const INIT_STATE = {
  loading: false,
  isUpdated: false,
  isError: false,
  isReceived: false,
  parametrosGlobais: null,
  msg: ''
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PARAMETROS_GLOBAIS_INIT:
      return { ...state, loading: true, isUpdated: false, isReceived: false };
    case GET_PARAMETROS_GLOBAIS_SUCCESS: {
      const { parametrosGlobais } = action.payload;
      return {
        ...state,
        parametrosGlobais,
        loading: false,
        isError: false,
        isReceived: true
      };
    }
    case UPDATE_PARAMETROS_GLOBAIS_INIT:
      return {
        ...state,
        loading: true,
        isUpdated: false,
        isReceived: false
      };
    case UPDATE_PARAMETROS_GLOBAIS_SUCCESS: {
      const { msg } = action.payload;
      return {
        ...state,
        loading: false,
        msg,
        isUpdated: true,
        isError: false,
        isReceived: false
      };
    }
    case PARAMETROS_GLOBAIS_ERROR_MSG: {
      const { msg } = action.payload;
      return { ...state, loading: false, isError: true, isReceived: false, msg };
    }
    case CLEAR_PARAMETROS_GLOBAIS: {
      return {
        ...state,
        isUpdated: false,
        isError: false,
        loading: false,
        isReceived: false,
        msg: ''
      };
    }
    default:
      return { ...state };
  }
};
